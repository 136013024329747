import React, { Component } from "react"
import ReactPlayer from "react-player"

export class Video extends Component {
  render() {
    const videoStyles = {
      margin: "0 auto",
    }

    return (
      <div className="text-center mx-auto w-full lg:w-1/2">
        <ReactPlayer
          url="/asc-video.mp4"
          playing="false"
          controls="true"
          width="100%"
          height="100%"
          pip="false"
          style={videoStyles}
        />
      </div>
    )
  }
}

export default Video
