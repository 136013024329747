import React from "react"
import { graphql } from "gatsby"
import Navigation from "../components/navigation"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Video from "../components/video"
import Mision from "../images/SVG/mision.svg"
import Vision from "../images/SVG/vision.svg"
import Objetivo from "../images/SVG/objetivo.svg"
import Especifico from "../images/SVG/especifico.svg"
import Footer from "../components/footer"

const IndexPage = props => (
  <Layout>
    <SEO title="Inicio" />
    <Navigation />

    <div className="w-full mx-auto top-illustration">
      <Img fluid={props.data.oneImage.childImageSharp.fluid} className="h-64" />
    </div>

    <div className="w-full mx-auto text-center bg-blue-200 pt-8 pb-8">
      <h1 className="text-3xl lg:text-4xl text-blue-900">
        Mindfulness y arteterapia para la niñez
      </h1>
      <div>
        <p className="w-10/12 lg:w-1/2 text-lg mt-3 mx-auto">
          Queremos que los niños sean felices y saludables, no solo ahora, si no
          por el resto de sus vidas. Enseñar meditación a la niñez los va a
          ayudar a seguir siendo felices. Por eso existe Sueña en Colores.
        </p>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8">
      <Video />
    </div>

    <div className="w-full mx-auto bg-green-100 pt-8 pb-8">
      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Mision}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-3xl my-3 px-5 text-blue-800">Misión</h2>
          <p className="px-5">
            Programas y talleres de apoyo psico-social. Mindfulness, arte y
            creatividad para niños en riesgo. Herramientas que promuevan balance
            emocional y el cómo lidiar con los obstáculos que se presentan en
            sus vidas.
          </p>
        </div>
      </div>

      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto mt-8">
        <div className="w-full lg:w-1/4 text-center lg:order-last">
          <img
            src={Vision}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-3xl my-3 px-5 text-blue-800">Visión</h2>
          <p className="px-5">
            El bienestar integral: crear un ambiente amistoso y estable donde se
            valore la importancia de la salud mental.
          </p>
        </div>
      </div>

      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto mt-8">
        <div className="w-full lg:w-1/4 text-center">
          <img
            src={Objetivo}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>

        <div className="w-full lg:w-3/4">
          <h2 className="text-3xl my-3 px-5 text-blue-800">Objetivo General</h2>
          <p className="px-5">
            Generar el bienestar y la salud mental en poblaciones infantiles
            vulnerables, a través de la psicología, el Mindfulness.
          </p>
        </div>
      </div>

      <div className="content-item w-full lg:w-10/12 lg:flex mx-auto mt-8">
        <div className="w-full lg:w-1/4 text-center lg:order-last">
          <img
            src={Especifico}
            alt="Mindfulness y Arteterapa"
            className="mx-auto w-1/2 lg:w-8/12"
          />
        </div>
        <div className="w-full lg:w-3/4">
          <h2 className="text-3xl my-3 px-5 text-blue-800">
            Objetivo Específico
          </h2>
          <p className="px-5">
            Desarrollar habilidades emocionales que les permita realizarse y
            responder a la vida en diversos contextos: familiares, comunitarios
            y académicos.
          </p>
        </div>
      </div>
    </div>

    <div className="w-full mx-auto text-center bg-gray-100 pt-8 pb-8">
      <h1 className="text-2xl lg:text-4xl text-blue-900">
        Los objetivos de desarrollo sostenible en que contribuimos
      </h1>
      <div className="mt-5">
        <p className="w-2/3 lg:w-8/12 lg:w-1/2 text-sm lg:text-lg mt-3 mx-auto">
          Garantizar una vida sana y promover el bienestar de todos a todas las
          edades.
        </p>

        <p className="w-2/3 lg:w-8/12 lg:w-1/2 text-sm lg:text-lg mt-8 mx-auto">
          Promover sociedades pacíficas e inclusivas, facilitar el acceso a la
          justicia y construir instituciones eficaces que rindan cuentas.
        </p>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default IndexPage

export const anaQuery = graphql`
  query {
    oneImage: file(relativePath: { eq: "meditate.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
